import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, title, image }) => {
  
   const { site } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  title
                  description
                  author
                  siteUrl
               }
            }
         }
      `
   )

   const renderGoogleAnalytics = () => {
      const scriptsGA = [];
      scriptsGA.push(
         <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS}`}></script>
         );
      scriptsGA.push(
         <script key="function" id="google-analytics-script"> 
            {`window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS}');`}
         </script>
      );

      return scriptsGA
   } 

   const renderGoogleAds = () => {
      const scriptsGAds = [];
      scriptsGAds.push(
         <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ADWORDS}`}></script>
      );
      scriptsGAds.push(
         <script key="function" id="google-ads-script"> 
            {`window.dataLayer = window.dataLayer || []; 
            function gtag() {dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', '${process.env.GATSBY_GOOGLE_ADWORDS}');`}
         </script>
      );

      return scriptsGAds
   } 

   const renderMetaPixel = () => {
      const scriptsMeta = [];
      scriptsMeta.push(
         <script key="function" id="facebook-pixel-script">
            {`!function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=3Df.fbq=3Dfunction(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=3Dn;n.push=3Dn;n.loaded=3D!0;n.version=3D'2.0';
           n.queue=3D[];t=3Db.createElement(e);t.async=3D!0;
           t.src=3Dv;s=3Db.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window,document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '${process.env.GATSBY_META_PIXEL}');
           fbq('track', 'PageView');`}
         </script>
      );
      scriptsMeta.push(
         <noscript key="image" id="facebook-pixel-image">
            {`<img height=3D"1" width=3D"1"
           src=3D"https://www.facebook.com/tr?id=3D${process.env.GATSBY_META_PIXEL}&ev=3DPageView
           &noscript=3D1"/>`}
         </noscript>
      );
   
      return scriptsMeta;
   };
   
   return (
      <Helmet
         htmlAttributes={{
            lang,
         }}
         title={title}
         titleTemplate={`%s | ${site.siteMetadata.title}`}
      >
         {renderGoogleAds()}
         {renderGoogleAnalytics()}
         {renderMetaPixel()}
      </Helmet>
   )
}

SEO.defaultProps = {
   lang: `pt-br`,
   meta: [],
   description: ``,
   keywords: []
}

SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
}


export default SEO